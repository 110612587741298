import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloudDoneTwoToneIcon from "@mui/icons-material/CloudDoneTwoTone";
import SdStorageTwoToneIcon from "@mui/icons-material/SdStorageTwoTone";
import LocalDrinkTwoToneIcon from "@mui/icons-material/LocalDrinkTwoTone";
import BucketTable from "../components/BucketTable";
import CustomSearch from "../components/CustomSearch";
import ErrorPage from "./ErrorPage";
import {
  ClientName,
  BucketSearch,
  UserPermissions,
  DarkMode,
  TokenData,
  NewAlert,
  BucketTableData,
} from "../context";
import FallStreakAPI from "../api/FallStreakApi";
import { useMsal } from "@azure/msal-react";

export default function Dashboard() {
  const { instance, accounts } = useMsal();
  const { tokenData } = useContext(TokenData);
  const { bucketTableData, setBucketTableData } = useContext(BucketTableData);
  const [activeStorage, setActiveStorage] = useState(0);
  const [activeObjects, setActiveObjects] = useState(0);
  const [activeBuckets, setActiveBuckets] = useState(0);
  const { setNewAlert } = useContext(NewAlert);
  const { setClientNameString, clientNameString } = useContext(ClientName);
  const { darkMode } = useContext(DarkMode);
  const { bucketSearchTerm, setBucketSearchTerm } = useContext(BucketSearch);
  const { setUserPermissions, userPermissions } = useContext(UserPermissions);
  const [errorPageDetails, setErrorPageDetails] = useState([]);
  const [clientFilter, setClientFilter] = useState("");
  const [clientArray, setClientArray] = useState([]);
  const bucketRefresh = useRef(false);
  const bucketInfo = useRef(false);

  function storageLabel(size) {
    var numberString = size.toString();
    if (numberString.length < 10) {
      return `${Math.round(((size / 1024 / 1024) * 100) / 100)} Mb`;
    } else if (numberString.length < 13) {
      return `${Math.round((size / 1024 / 1024 / 1024) * 100) / 100} Gb`;
    } else if (numberString.length < 16) {
      return `${Math.round((size / 1024 / 1024 / 1024 / 1024) * 100) / 100} Tb`;
    } else if (numberString.length < 19) {
      return `${
        Math.round((size / 1024 / 1024 / 1024 / 1024 / 1024) * 100) / 100
      } Pb`;
    }
  }

  function FormatBucketDataforTable() {
    var bucketDataList = [];
    let client_name_array = ["All"];
    bucketTableData[0]?.name &&
      bucketTableData.forEach((element) => {
        let lowerCaseName = element?.name.toLowerCase();
        if (
          element?.clientname.includes(clientFilter) ||
          clientFilter === "All"
        ) {
          if (
            lowerCaseName.includes(bucketSearchTerm.toLowerCase()) ||
            bucketSearchTerm === ""
          ) {
            bucketDataList.push({
              name: element?.name,
              url: element?.url,
              usage: element?.size ? storageLabel(element?.size) : "0Mb",
              graph: {
                size: element?.size,
                quota: element?.details?.quota?.quota,
              },
              keys: element?.keys ? element?.keys : {},
              clientname: element?.clientname,
              objects: element?.objects ? element?.objects : 0,
              quota: element?.details?.quota?.quota
                ? storageLabel(element?.details?.quota?.quota)
                : "N/A",
              type: element?.details?.quota?.type,
              date: element?.creation_date,
            });
            if (!client_name_array.includes(element?.clientname)) {
              client_name_array.push(element?.clientname);
            }
          }
        }
      });
    !clientArray.length && setClientArray(client_name_array);
    return bucketDataList;
  }

  // handle API being down
  useEffect(() => {
    function TestHealth() {
      FallStreakAPI("", "GET", "health", "")
        .then(function (response) {
          if (response?.status !== 200) {
            setNewAlert({
              color: "error",
              message: "The system is not responding. Please contact support",
            });
            setErrorPageDetails([
              "The system is not responding.",
              "Please contact support",
            ]);
          }
        })
        .catch(function (err) {
          console.log(err);
          setNewAlert({ color: "error", message: err });
        });
    }
    const timeoutId = setTimeout(() => {
      TestHealth();
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [setNewAlert, setErrorPageDetails]);

  function UpdateDashboardCards(dashboard_data) {
    let usedSpace = 0;
    let totalObjects = 0;
    let activeBuckets = 0;
    dashboard_data.forEach((element) => {
      if (element?.size) {
        usedSpace = usedSpace + element?.size;
        totalObjects = totalObjects + element?.objects;
      }
      activeBuckets++;
    });
    setActiveBuckets(activeBuckets);
    setActiveObjects(totalObjects);
    setActiveStorage(storageLabel(usedSpace));
  }

  const BucketInfo = useCallback(
    (propTokenData) => {
      if (!bucketRefresh.current) {
        FallStreakAPI({ token_data: propTokenData }, "POST", "bucketinfo", "")
          .then(function (response) {
            if (response?.status === 200) {
              if (response?.data) {
                setBucketTableData(response.data);
                UpdateDashboardCards(response.data);
              } else {
                setBucketTableData([]);
                setNewAlert({
                  color: "info",
                  message: "No Buckets are found",
                });
              }
            } else if (response?.status === 401) {
              setNewAlert({
                color: "error",
                message: response?.response?.data?.detail,
              });
              setErrorPageDetails([response?.response?.data?.detail]);
            } else if (response?.status === 403) {
              if (bucketTableData) {
                setNewAlert({
                  color: "error",
                  message: "Failed to refresh bucket information",
                });
              } else {
                setNewAlert({
                  color: "error",
                  message: response?.response?.data?.detail,
                });
                setErrorPageDetails([response?.response?.data?.detail]);
              }
            }
            bucketRefresh.current = true;
          })
          .catch(function (err) {
            console.log(err);
            setNewAlert([{ color: "error", message: err }]);
          });
      }
    },
    [bucketTableData, setNewAlert, setErrorPageDetails, setBucketTableData]
  );

  const ClientInfo = useCallback(
    (propTokenData) => {
      if (!bucketInfo.current) {
        FallStreakAPI({ token_data: propTokenData }, "POST", "clientinfo", "")
          .then(function (response) {
            if (response?.status === 200) {
              if (response?.data) {
                setClientNameString(response?.data?.clientname);
                if (Object.keys(response?.data?.permissions).length === 0) {
                  setErrorPageDetails([
                    "You currently have no bucket access.",
                    "Contact your administrator to request permissions",
                  ]);
                  setUserPermissions(response?.data?.permissions);
                } else {
                  setUserPermissions(response?.data?.permissions);
                  BucketInfo(propTokenData);
                }
              }
            } else if (response?.status === 401) {
              setNewAlert({
                color: "error",
                message: response?.response?.data?.detail,
              });
              setErrorPageDetails([response?.response?.data?.detail]);
            } else if (response?.status === 403) {
              if (response?.response?.data?.detail === "Not whitelisted") {
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                  idTokenHint: accounts[0]?.idToken,
                });
              } else {
                setNewAlert({
                  color: "error",
                  message: response?.response?.data?.detail,
                });
                setErrorPageDetails([response?.response?.data?.detail]);
              }
            }
            bucketInfo.current = true;
          })

          .catch(function (err) {
            console.log(err);
            setNewAlert([{ color: "error", message: err }]);
          });
      }
    },
    [
      setClientNameString,
      setUserPermissions,
      setNewAlert,
      BucketInfo,
      setErrorPageDetails,
      instance,
      accounts,
    ]
  );

  useEffect(() => {
    if (tokenData) {
      ClientInfo(tokenData);
      const interval = setInterval(() => {
        bucketInfo.current = false;
        bucketRefresh.current = false;
        ClientInfo(tokenData);
      }, 120000);
      return () => clearInterval(interval);
    }
  }, [tokenData, ClientInfo, clientNameString]);

  return !errorPageDetails.length ? (
    bucketTableData ? (
      <Grid2
        columnSpacing={5}
        rowSpacing={3}
        container
        sx={{ paddingTop: "1vh" }}
      >
        <Grid2 size={{ xs: 12, md: 4 }}>
          <Card className={darkMode ? "appcardsdark" : "appcards"}>
            <CardHeader
              className={darkMode ? "appcardstitledark" : "appcardstitle"}
              title="Active Storage"
            />
            <CardContent
              className={darkMode ? "appcardscontentdark" : "appcardscontent"}
            >
              <Grid2 container columnSpacing={3}>
                <Grid2>
                  <CloudDoneTwoToneIcon
                    color="success"
                    sx={{ color: darkMode && "#ffffff", fontSize: 30 }}
                  />
                </Grid2>
                <Grid2>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ color: darkMode ? "#ffffff" : "#001864" }}
                  >
                    {activeStorage}
                  </Typography>
                </Grid2>
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <Card className={darkMode ? "appcardsdark" : "appcards"}>
            <CardHeader
              className={darkMode ? "appcardstitledark" : "appcardstitle"}
              title="Total Objects"
            />
            <CardContent
              className={darkMode ? "appcardscontentdark" : "appcardscontent"}
            >
              <Grid2 container columnSpacing={5}>
                <Grid2>
                  <SdStorageTwoToneIcon
                    color="primary"
                    sx={{ color: darkMode && "#ffffff", fontSize: 30 }}
                  />
                </Grid2>
                <Grid2>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ color: darkMode ? "#ffffff" : "#001864" }}
                  >
                    {activeObjects}
                  </Typography>
                </Grid2>
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <Card className={darkMode ? "appcardsdark" : "appcards"}>
            <CardHeader
              className={darkMode ? "appcardstitledark" : "appcardstitle"}
              title="Number of Buckets"
            />
            <CardContent
              className={darkMode ? "appcardscontentdark" : "appcardscontent"}
            >
              <Grid2 container columnSpacing={5}>
                <Grid2>
                  <LocalDrinkTwoToneIcon
                    color="secondary"
                    sx={{
                      color: darkMode && "#ffffff",
                      fontSize: 30,
                    }}
                  />
                </Grid2>
                <Grid2>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ color: darkMode ? "#ffffff" : "#001864" }}
                  >
                    {activeBuckets}
                  </Typography>
                </Grid2>
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <CustomSearch
            setSearchTerm={setBucketSearchTerm}
            searchTerm={bucketSearchTerm}
            placeholder={"Search for bucket..."}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 8 }}>
          {clientNameString === "Corpcloud" && (
            <Box sx={{ pl: "10px", pr: "10px" }}>
              <FormControl fullWidth>
                <InputLabel
                  id="client-select-label"
                  sx={{ color: darkMode ? "white" : "#001864" }}
                >
                  Filter Clients
                </InputLabel>
                <Select
                  id="filter-select"
                  value={clientFilter}
                  label="Filter Clients"
                  onChange={(e) => setClientFilter(e.target.value)}
                  sx={{
                    backgroundColor: darkMode ? "#242d3e" : "#ffffff",
                    color: darkMode ? "white" : "#001864",
                    Width: "100%",
                  }}
                >
                  {clientArray.map((option, i) => (
                    <MenuItem
                      key={i}
                      value={option}
                      className={
                        darkMode
                          ? "customsearchlistitemdark ifhovered"
                          : "customsearchlistitem ifhovered"
                      }
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Grid2>
        <Card
          className={darkMode ? "appcardsdark" : "appcards"}
          sx={{ width: "100%" }}
        >
          <BucketTable
            bucketTableData={FormatBucketDataforTable()}
            clientFilter={clientFilter}
          />
        </Card>
      </Grid2>
    ) : (
      <Box className="companyname">
        <CircularProgress color="success" />
      </Box>
    )
  ) : (
    <ErrorPage errorPageDetails={errorPageDetails} />
  );
}
